<script setup lang="ts">
import { TheMobileNavbar, TheNavbar, TheSnackbar } from '@/core/components';
import { comingSoonPages } from '@/enums/ComingSoon';

const route = useRoute();
</script>

<template>
  <v-layout>
    <TheMobileNavbar />
    <TheNavbar class="navbar" />
    <v-main>
      <NuxtPage class="px-2" :page-key="(route) => route.fullPath" />
      <TheSnackbar />
    </v-main>
  </v-layout>
</template>

<style lang="scss" scoped>
.v-layout {
  display: block;
  background-color: rgb(var(--v-theme-page-background));
}

.v-main {
  padding-left: 0 !important;
  padding-top: 59px + 40px !important;

  @media (min-width: 768px) {
    padding-left: 248px !important;
    padding-top: 40px !important;
  }
}
</style>
